import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {
    --font-size__h0: 3.583rem;

    /* minecraft colors */
    --color__mc-2: #0000aa;
    --color__mc-5: #ff55ff;
    --color__mc-8: #555555;
    --color__mc-7: #aaaaaa;
    --color__mc-9: #5555ff;

    --color__mc-b: #55ffff;
    --color__mc-c: #ff5555;
    --color__mc-e: #ffff55;

    /* brand colors */
    --color__1: #20142A;
    --color__2: #202C4A;
    --color__3: #1E4B53;
    --color__4: #286F50;
    --color__5: #398447;


    /* Black (black)	§0	\u00A70	000000
    Dark Blue (dark_blue)	§1	\u00A71	0000AA
    Dark Green (dark_green)	§2	\u00A72	00AA00
    Dark Aqua (dark_aqua)	§3	\u00A73	00AAAA
    Dark Red (dark_red)	§4	\u00A74	AA0000
    Dark Purple (dark_purple)	§5	\u00A75	AA00AA
    Gold (gold)	§6	\u00A76	FFAA00
    Gray (gray)	§7	\u00A77	AAAAAA
    Dark Gray (dark_gray)	§8	\u00A78	555555
    Blue (blue)	§9	\u00A79	5555FF
    Green (green)	§a	\u00A7a	55FF55
    Aqua (aqua)	§b	\u00A7b	55FFFF
    Red (red)	§c	\u00A7c	FF5555
    Light Purple (light_purple)	§d	\u00A7d	FF55FF
    Yellow (yellow)	§e	\u00A7e	FFFF55
    White (white)	§f	\u00A7f	FFFFFF */

    --font-family: 'Poppins', sans-serif;

    --font-size__h1: 2.074rem;
    --font-size__h2: 1.728rem;
    --font-size__h3: 1.44rem;
    --font-size__h4: 1.2rem;
    --font-size__h5: 1rem;
    --font-size__small: 0.833rem;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    font-size: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-family);
    font-weight: 400;
    line-height: 1.75;
    color: var(--color__1);
  }

  p {
    margin-bottom: 1rem;
}

  h1, h2, h3, h4, h5 {
    margin: 3rem 0 1.38rem;
    font-family: var(--font-family);
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: -0.03em;
  }

  h1 {
    margin-top: 0;
    font-size: var(--font-size__h1);
  }

  h2 {
    font-size: var(--font-size__h2);
  }

  h3 {
    font-size: var(--font-size__h3);
  }

  h4 {
    font-size: var(--font-size__h4);
  }

  h5 {
    font-size: var(--font-size__h5);
  }

  code {
    background-color: #F0F4F8;
    padding: 3px 5px;
    border-radius: 4px;
    margin-bottom: 10px;
    display: inline-block;
  }

  pre {
    code {
      display: block;
      padding: 30px;
      border-radius: 10px;
    }
  }

  a {
    text-decoration: none;
  }

  .container {
    width: 100%;
    margin: 0 auto;
    padding-right: 50px;
    padding-left: 50px;
  }

  .tooltip {
    font-family: "Formal436 BT", monospace;
  }
`
