import React from 'react'
import PropTypes from 'prop-types'
import 'normalize.css'
import { GlobalStyle } from '../assets/styles/global'
import { Header } from './Header'

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Header />
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
