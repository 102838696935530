import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

const NotFoundPage = () => {
  return (
    <Layout>
      <h1>Page Not Found</h1>
      <Link to="/">Go home</Link>.
    </Layout>
  )
}

export default NotFoundPage
