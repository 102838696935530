import React from 'react'
import { Link } from 'gatsby'
import { StyledHeader } from './Header.styles'
import logo from '../../assets/images/logo.svg'

const Header = () => {
  return (
    <StyledHeader>
      <div className="container">
        <Link to="/">
          <img alt="Smashcraft Logo" src={logo} />
        </Link>
        <nav>
          <Link to="/">Home</Link>
          {/* <Link to="/">Rules</Link> */}
          {/* <Link to="/">Vote</Link> */}
          {/* <Link to="/docs">Docs</Link> */}
          <a
            href="https://smashbox.buycraft.net/"
            rel="noreferrer"
            target="_blank"
          >
            Store
          </a>
        </nav>
      </div>
    </StyledHeader>
  )
}

export default Header
