import styled from 'styled-components'

export const StyledHeader = styled.header`
  background: var(--color__1);
  display: flex;
  align-items: center;
  height: 70px;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }

  img {
    width: 240px;
    display: block;
  }

  a {
    color: #fff;
  }

  nav {
    display: flex;
    gap: 20px;
    font-weight: 500;
  }
`
